var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "news-catalog" },
    [
      _c(
        "content-wrapper-block",
        [
          false
            ? _c(
                "ui-tab-bar",
                {
                  model: {
                    value: _vm.activeTab,
                    callback: function ($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab",
                  },
                },
                _vm._l(_vm.newsTab, function (tab, index) {
                  return _c(
                    "ui-tab-bar-tab",
                    { key: index, attrs: { value: tab.category } },
                    [_vm._v(" " + _vm._s(tab.category) + ". ")]
                  )
                }),
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "grid-news-catalog" },
            _vm._l(_vm.news || _vm.newsList, function (item, index) {
              return _c("card-news", {
                key: index,
                staticClass: "grid-news-catalog__item",
                attrs: {
                  id: item.id,
                  date: _vm.formatDate(item.published_at),
                  title: item.title,
                  "short-description": item.short_content,
                  position: _vm.position(index),
                  img: _vm.formatLink(item.preview.image_thumb),
                  shadow: true,
                  to: {
                    name: "InnerNewsPage",
                    params: { slug: item.slug },
                  },
                },
              })
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "news-catalog__more" },
            [
              _c(
                "ui-button",
                {
                  attrs: { disabled: _vm.disabledMoreButton },
                  on: {
                    click: function ($event) {
                      return _vm.loadMorePublications(_vm.nextPageLinkString)
                    },
                  },
                },
                [_vm._v(" Показать еще ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="news-catalog-page">
    <page-header light dark-crumb no-padding title="Новости" />
    <news-catalog-block
      :news-tab="[
        {
          active: true,
          category: '2020 г',
        },
        {
          category: '2019 г',
        },
        {
          category: '2018 г',
        },
        {
          category: '2017 г',
        },
      ]"
      :news-list="newsList"
      :meta="{ links, meta }"
    />
    <callback-block id="callback" id-formblock="question-block__callback" />
  </div>
</template>

<script>
import PageHeader from "@/components/elements/PageHeader";
import CallbackBlock from "@/components/blocks/CallbackBlock";
import NewsCatalogBlock from "@/components/blocks/NewsCatalogBlock";
import axios from "axios";

export default {
  name: "NewsCatalogPage",
  components: {
    PageHeader,
    CallbackBlock,
    NewsCatalogBlock,
  },
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Свежие новости о проекте ЖК MainStreet. Подробно о ходе строительства, архитектуре и благоустройстве дома.",
      },
    ],
  },
  data: () => ({
    newsList: null,
    links: null,
    meta: null,
  }),
  created() {
    this.loadPublications(
      process.env.VUE_APP_PROJECT_ID,
      process.env.VUE_APP_NEWS_ID,
      "ru",
      17,
      1
    );
  },
  methods: {
    loadPublications(project, category, language, perPage, page) {
      axios
        .get(process.env.VUE_APP_API_ADMIN + "api/v1" + "/publications", {
          params: {
            project: project,
            category: category,
            language: language,
            per_page: perPage,
            page: page || 1,
          },
        })
        .then((response) => {
          this.newsList = response.data.data;
          this.links = response.data.links;
          this.meta = response.data.meta;
        })
        .catch((error) => {
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
          } else {
            // Something happened in setting up the request and triggered an Error
          }
          this.$router.push({ name: "MainPage" }); // redirect to MainPage
        })
        .finally(() => {
          // this.setLoading(false)
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>

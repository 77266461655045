var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "news-catalog-page" },
    [
      _c("page-header", {
        attrs: {
          light: "",
          "dark-crumb": "",
          "no-padding": "",
          title: "Новости",
        },
      }),
      _c("news-catalog-block", {
        attrs: {
          "news-tab": [
            {
              active: true,
              category: "2020 г",
            },
            {
              category: "2019 г",
            },
            {
              category: "2018 г",
            },
            {
              category: "2017 г",
            },
          ],
          "news-list": _vm.newsList,
          meta: { links: _vm.links, meta: _vm.meta },
        },
      }),
      _c("callback-block", {
        attrs: { id: "callback", "id-formblock": "question-block__callback" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
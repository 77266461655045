<template>
  <div class="news-catalog">
    <content-wrapper-block>
      <ui-tab-bar v-if="false" v-model="activeTab">
        <ui-tab-bar-tab
          v-for="(tab, index) in newsTab"
          :key="index"
          :value="tab.category"
        >
          {{ tab.category }}.
        </ui-tab-bar-tab>
      </ui-tab-bar>
      <div class="grid-news-catalog">
        <card-news
          v-for="(item, index) in news || newsList"
          :id="item.id"
          :key="index"
          class="grid-news-catalog__item"
          :date="formatDate(item.published_at)"
          :title="item.title"
          :short-description="item.short_content"
          :position="position(index)"
          :img="formatLink(item.preview.image_thumb)"
          :shadow="true"
          :to="{
            name: 'InnerNewsPage',
            params: { slug: item.slug },
          }"
        />
      </div>
      <div class="news-catalog__more">
        <ui-button
          :disabled="disabledMoreButton"
          @click="loadMorePublications(nextPageLinkString)"
        >
          Показать еще
        </ui-button>
      </div>
    </content-wrapper-block>
  </div>
</template>

<script>
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import UiTabBar from "@/components/ui/UITabBar";
import UiTabBarTab from "@/components/ui/UITabBarTab";
import CardNews from "@/components/elements/CardNews";
import UiButton from "@/components/ui/UIButton";
import formatD from "@/mixins/formatD";
import isMobile from "@/mixins/isMobile";
import replLocalToApi from "@/mixins/replLocalToApi";
import axios from "axios";

export default {
  name: "NewsCatalogBlock",
  components: {
    ContentWrapperBlock,
    UiTabBar,
    UiTabBarTab,
    CardNews,
    UiButton,
  },
  props: {
    /**
     * :newsTab="[
     *   {
     *    category: String,
     *    active: Boolean
     *   }
     * ]"
     */
    newsTab: {
      type: Array,
      required: true,
    },
    newsList: {
      type: Array,
      default: null,
      require: true,
    },
    // :meta="{links, meta}"
    meta: {
      type: Object,
      default: null,
      require: true,
    },
  },
  data() {
    return {
      activeTab: this.activityTabs(),
      disabledMoreButton: false,
      nextPageLinkString: null,
      news: null,
    };
  },
  methods: {
    activityTabs() {
      return this.newsTab.filter((tab) => tab.active)[0].category;
    },

    loadMorePublications(nextPageUrlString) {
      const nextPageUrl = new URLSearchParams(
        nextPageUrlString || this.meta.links.next
      );
      const params = {
        project: nextPageUrl.get("project") || process.env.VUE_APP_PROJECT_ID,
        category: nextPageUrl.get("category") || process.env.VUE_APP_NEWS_ID,
        language: nextPageUrl.get("language") || "ru",
        per_page: 15 || nextPageUrl.get("per_page"),
        page: nextPageUrl.get("page"),
      };
      // Выключаем кнопоку до выполнения запроса
      this.disabledMoreButton = true;
      axios
        .get(process.env.VUE_APP_API_ADMIN + "api/v1" + "/publications", {
          params: params,
        })
        .then((response) => {
          // Создаем ссылку на сл.страницу или пустое поле
          this.nextPageLinkString = response.data.links.next || "";
          if (this.news == null) {
            this.news = this.newsList;
          }
          // Перебираем ответ и добавляем ко всем
          response.data.data.forEach((item) => {
            this.news.push(item);
          });
        })
        .catch((error) => {
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
          } else {
            // Something happened in setting up the request and triggered an Error
          }
        })
        .finally(() => {
          // Включаем кнопку
          // Если поле пустое, значит нет сл. страниц и кнопка выключена
          this.disabledMoreButton = this.nextPageLinkString === "";

          // если кнопка не активна то скрываем её
          if (this.disabledMoreButton === true) {
            document.querySelector(".news-catalog__more").style.display =
              "none";
          }
        });
    },
    position(index) {
      let out = "";
      if (Number(index) === 2 && !isMobile()) {
        out = "top";
      } else if (Number(index) === 9 && !isMobile()) {
        out = "left";
      }
      return out;
    },
    formatDate(str) {
      return formatD(str, "dd.MM.yyyy");
    },
    formatLink(str) {
      if (str !== null) {
        return replLocalToApi(str);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$gridHeight: 228px;

.news-catalog {
  &__more {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }

  .content-wrapper {
    padding-top: 0;
  }
}
.grid-news-catalog {
  grid-template-columns: repeat(auto, 270px);
  grid-template-rows: repeat(auto, $gridHeight);
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: $gridHeight;
  gap: 20px;
  grid-template-areas:
    "left-top"
    "right-top"
    "big"
    "left-bottom"
    "right-bottom"
    "line-one"
    "line-two"
    "line-tree"
    "line-fore"
    "big-inline"
    "duble-inline-left"
    "left-inline"
    "right-inline";
  margin: 30px auto;

  .left-top,
  &__item:nth-of-type(1) {
    grid-area: left-top;
  }
  .right-top,
  &__item:nth-of-type(2) {
    grid-area: right-top;
  }
  .big,
  &__item:nth-of-type(3) {
    grid-area: big;
  }
  .right-bottom,
  &__item:nth-of-type(4) {
    grid-area: right-bottom;
  }
  .left-bottom,
  &__item:nth-of-type(5) {
    grid-area: left-bottom;
  }
  .line-one,
  &__item:nth-of-type(6) {
    grid-area: line-one;
  }
  .line-two,
  &__item:nth-of-type(7) {
    grid-area: line-two;
  }
  .line-tree,
  &__item:nth-of-type(8) {
    grid-area: line-tree;
  }
  .line-fore,
  &__item:nth-of-type(9) {
    grid-area: line-fore;
  }
  .big-inline,
  &__item:nth-of-type(10) {
    grid-area: big-inline;
  }
  .duble-inline-left,
  &__item:nth-of-type(11) {
    grid-area: duble-inline-left;
  }
  .left-inline,
  &__item:nth-of-type(12) {
    grid-area: left-inline;
  }
  .right-inline,
  &__item:nth-of-type(13) {
    grid-area: right-inline;
  }
  @media screen and (min-width: $screen-lg) {
    grid-template-columns: repeat(4, 270px);
    grid-template-rows: repeat(5, 228px);
    gap: 30px;
    grid-template-areas:
      "left-top right-top big big"
      "left-bottom right-bottom big big"
      "line-one line-two line-tree line-fore"
      "big-inline big-inline big-inline big-inline"
      "duble-inline-left duble-inline-left left-inline right-inline";
  }
  .card-news-img {
    width: 100%;
  }
}
</style>
